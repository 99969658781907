import React, { FC } from 'react';
import styles from './PlatformLabel.module.scss';
import IPlatformLabel from './IPlatformLabel';
import classNames from 'classnames';

const PlatformLabel: FC<IPlatformLabel> = ({ className, text, type }) => {
	return (
		<div className={classNames(styles['platform-label'], styles[type], className)}>
			<span className={styles.text}>{text || 'Read more'}</span>
		</div>
	);
};

export default PlatformLabel;
