import React, { FC } from 'react';
import styles from './Header.module.scss';
import Navigation from '../navigation/Navigation';
import logo from '../../../assets/images/logo-gravity-security.png';
import Button from '../../primitives/button/Button';
import MobileNavigation from '../mobile-navigation/MobileNavigation';
import { IActiveMenuHandler } from '../../../utils/common';

const Header: FC<IActiveMenuHandler> = (props) => {
	return (
		<header className={styles.header}>
			<div className={styles.container}>
				<div className={styles.logo}>
					<img src={logo} alt="Gravity Security" />
				</div>

				<Navigation {...props} className={styles.navigation} />

				<MobileNavigation {...props} className={styles['mobile-navigation']} />

				<div className={styles.actions}>
					<Button
						negative
						width={102}
						className={styles.action}
						href="https://platform.gravitygrc.com/login"
					>
						Log In
					</Button>

					<Button
						width={174}
						className={styles.action}
						href="mailto:info@gravity-security.com"
					>
						Get GRC Access
					</Button>
				</div>
			</div>
		</header>
	);
};

export default Header;
