import React, { FC, useState } from 'react';
import styles from './MobileNavigation.module.scss';
import IMobileNavigation from './IMobileNavigation';
import { PageSections } from '../../../utils/constants';
import classNames from 'classnames';
import useScrollableSetting from '../../../utils/hooks/useScrollableSetting';
import useDevice from '../../../utils/hooks/useDevice';

const MobileNavigation: FC<IMobileNavigation> = ({
	className,
	activeMenuItem,
	scrollToSection,
}) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const device = useDevice();

	const toggleMenu = (sectionKey?: string) => {
		setIsMenuOpen((prev) => !prev);
		if (sectionKey) scrollToSection(sectionKey);
	};

	useScrollableSetting(isMenuOpen && device !== 'desktop', document.body);

	return (
		<nav className={classNames(styles['mobile-navigation'], className)}>
			<div
				className={classNames(styles.toggler, isMenuOpen ? styles.opened : '')}
				onClick={() => toggleMenu()}
			></div>

			<div className={classNames(styles.popup, isMenuOpen ? styles.open : '')}>
				{PageSections.map((section) => (
					<span
						key={section.key}
						className={classNames(
							styles.item,
							activeMenuItem === section.key ? styles.active : '',
						)}
						onClick={() => toggleMenu(section.key)}
					>
						{section.displayName}
					</span>
				))}
			</div>
		</nav>
	);
};

export default MobileNavigation;
