import React, { FC, useEffect } from 'react';
import styles from './PlatformShowcase.module.scss';
import policiesScreen from '../../../../assets/images/screenshot-policies.jpg';
import controlsScreen from '../../../../assets/images/screenshot-controls.jpg';
import trustHubScreen from '../../../../assets/images/screenshot-trusthub.png';
import ReadMore from '../../../primitives/read-more/ReadMore';
import PlatformLabel from '../../../primitives/platform-label/PlatformLabel';
import ColoredTag from '../../../primitives/tag/ColoredTag';
import classNames from 'classnames';
import { PageSectionKeys } from '../../../../utils/constants';

const PlatformShowcase: FC = () => {
	useEffect(() => {
		const policiesTarget = document.querySelector(`.${styles.policies}`);
		const controlsTarget = document.querySelector(`.${styles.controls}`);
		const trustHubTarget = document.querySelector(`.${styles.trustHub}`);

		const options = {
			root: null,
			threshold: 0.7,
		};

		window.addEventListener('scroll', function (e) {
			if (policiesTarget) {
				const onPoliciesReach = (entries: any) => {
					if (entries[0].isIntersecting) policiesTarget.classList.add(styles.animate);
				};

				const observer = new IntersectionObserver(onPoliciesReach, options);
				observer.observe(policiesTarget);
			}

			if (controlsTarget) {
				const onControlsReach = (entries: any) => {
					if (entries[0].isIntersecting) controlsTarget.classList.add(styles.animate);
				};

				const observer = new IntersectionObserver(onControlsReach, options);
				observer.observe(controlsTarget);
			}

			if (trustHubTarget) {
				const onTrustHubReach = (entries: any) => {
					if (entries[0].isIntersecting) trustHubTarget.classList.add(styles.animate);
				};

				const observer = new IntersectionObserver(onTrustHubReach, options);
				observer.observe(trustHubTarget);
			}
		});

		return () => {
			window.removeEventListener('scroll', () => {});
		};
	}, []);

	return (
		<section className={styles.platform}>
			<div className={styles.container}>
				<h2>Platform Showcase</h2>

				<div className={classNames(styles.panel, styles.policies)}>
					<div className={styles.info}>
						<h3>Dynamic Policy Management</h3>

						<p>
							Automated Policy Updates: GravityGRC's dynamic policy management system
							automates the creation, updating, and dissemination of policy documents
							in real-time.
						</p>

						<ReadMore text="Learn More" onClickHandler={() => {}} />
					</div>

					<div className={styles.image}>
						<PlatformLabel
							className={styles['label-policies']}
							text="Policies"
							type="policies"
						/>

						<ColoredTag
							className={styles['tag-policies']}
							text="For review"
							borderColor="#FFE453"
						/>

						<img src={policiesScreen} alt="Policies" />
					</div>
				</div>

				<div className={classNames(styles.panel, styles.controls)}>
					<div className={styles.image}>
						<PlatformLabel
							className={styles['label-controls']}
							text="Controls"
							type="controls"
						/>

						<ColoredTag
							className={styles['tag-controls']}
							text="Approved"
							bgColor="#D1F0AB"
						/>

						<ColoredTag
							className={styles['tag-controls-empty']}
							text=""
							bgColor="#CAE1FE"
						/>

						<img src={controlsScreen} alt="Controls" />
					</div>

					<div className={styles.info}>
						<h3>Comprehensive Control Guidance</h3>

						<p>
							GravityGRC identifies the applicable controls and provides comprehensive
							implementation guidance, specific assignments, and tracking.
						</p>

						<ReadMore text="Learn More" onClickHandler={() => {}} />
					</div>
				</div>

				<div
					id={PageSectionKeys.trustHubPortal}
					className={classNames(styles.panel, styles.trustHub)}
				>
					<div className={styles.info}>
						<h3>TrustHUB Portal</h3>

						<p>
							TrustHUB is an external portal that enables secure, dynamic document
							sharing and controlled access to information security and privacy
							insights for third parties, with integrated GravityGRC visibility and an
							approval-based client access workflow.
						</p>

						<ReadMore text="Learn More" onClickHandler={() => {}} />
					</div>

					<div className={styles.image}>
						<PlatformLabel
							className={styles['label-trustHub']}
							text="TrustHUB Portal"
							type="trustHub"
						/>

						<img
							className={styles['rounded-img']}
							src={trustHubScreen}
							alt="TrustHUB"
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default PlatformShowcase;
