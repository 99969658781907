import React, { FC } from 'react';
import styles from './TopSection.module.scss';

const TopSection: FC = () => {
	return (
		<div className={styles.top}>
			<div className={styles.content}>
				<div className={styles.info}>
					<h1>
						GravityGRC -
						<span className={styles.highlighted}>Effortless Compliance</span>
					</h1>

					<p>
						GravityGRC stands at the forefront of innovation, transforming traditional
						GRC management with its dynamic and AI-driven approach. Designed to meet the
						needs of diverse industries and company sizes, GravityGRC abstracts static
						policy documents into actionable control statements.
					</p>
				</div>

				<div className={styles.frameworks}></div>
			</div>
		</div>
	);
};

export default TopSection;
