import { useEffect, useState } from 'react';
import IUseDevice from './IUseDevice';

const devicesSettings: IUseDevice[] = [
	{
		device: 'desktop',
		resolution: 1024,
	},
	{
		device: 'tablet',
		resolution: 770,
	},
	{
		device: 'mobile',
		resolution: 320,
	},
];

export default function useDevice() {
	const [activeDevice, setActiveDevice] = useState<'mobile' | 'tablet' | 'desktop' | null>(null);

	const getDevice = (windowWidth: number) => {
		const deviceObj: IUseDevice | undefined = devicesSettings.find(
			({ resolution }) => windowWidth >= resolution,
		);

		if (deviceObj) {
			return deviceObj.device;
		} else return null;
	};

	const getWindowOuterWidth = () => {
		const windowWidth = window.innerWidth;
		const device = getDevice(windowWidth);

		if (device) {
			setActiveDevice(device);
		}
	};

	useEffect(() => {
		getWindowOuterWidth();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		window.addEventListener('resize', getWindowOuterWidth);

		return () => window.removeEventListener('resize', getWindowOuterWidth);
		// eslint-disable-next-line
	}, []);

	return activeDevice;
}
