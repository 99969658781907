import React, { FC } from 'react';

import styles from './ColoredTag.module.scss';
import IColoredTag from './IColoredTag';
import classNames from 'classnames';

const ColoredTag: FC<IColoredTag> = ({
	text,
	className,
	bgColor,
	borderColor,
}) => {
	const getStyle = () => {
		return {
			...(borderColor ? { borderColor } : {}),
			...(bgColor ? { backgroundColor: bgColor } : {}),
		};
	};

	return (
		<div className={classNames(styles.tag, className)} style={getStyle()}>
			{text}
		</div>
	);
};

export default ColoredTag;
