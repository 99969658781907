import React, { FC } from 'react';
import PlatformShowcase from './platform-showcase/PlatformShowcase';
import FeaturesList from './features-list/FeaturesList';

const FeaturesSection: FC<{ scrollToSection: (key: string) => void }> = ({ scrollToSection }) => {
	return (
		<>
			<FeaturesList scrollToSection={scrollToSection} />

			<PlatformShowcase />
		</>
	);
};

export default FeaturesSection;
