export const PageSectionKeys = {
	home: 'home',
	trustHub: 'trustHub',
	trustHubPortal: 'trustHubPortal',
	about: 'about',
	contacts: 'contacts',
};

export const PageSections = [
	{
		displayName: 'Gravity GRC',
		key: PageSectionKeys.home,
	},
	{
		displayName: 'TrustHUB',
		key: PageSectionKeys.trustHub,
	},
	{
		displayName: 'About Us',
		key: PageSectionKeys.about,
	},
	{
		displayName: 'Contacts',
		key: PageSectionKeys.contacts,
	},
];
