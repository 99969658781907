import React, { FC } from 'react';
import styles from './ContactsSection.module.scss';
import logo from '../../../assets/images/logo-gravity-security.png';
import { IActiveMenuHandler } from '../../../utils/common';
import { PageSections } from '../../../utils/constants';

const ContactsSection: FC<IActiveMenuHandler> = ({ scrollToSection }) => {
	const menuItems = [...PageSections];
	menuItems.pop();

	return (
		<div className={styles.contacts}>
			<div className={styles.container}>
				<div className={styles.logo}>
					<img src={logo} alt="Gravity Security" width={100} />
				</div>

				<nav className={styles['bottom-navigation']}>
					{menuItems.map((section) => (
						<span
							key={section.key}
							className={styles.item}
							onClick={() => scrollToSection(section.key)}
						>
							{section.displayName}
						</span>
					))}
				</nav>

				<div className={styles.contact}>
					<div className={styles.item}>407-457-8237</div>
					<a href="mailto:info@gravity-security.com" className={styles.item}>
						info@gravity-security.com
					</a>
					<a href="mailto:chat@gravity-security.com" className={styles.item}>
						chat@gravity-security.com
					</a>
				</div>
			</div>

			<div className={styles.rights}>
				© {new Date().getFullYear()} Gravity Security, LLC. All Rights Reserved.
			</div>
		</div>
	);
};

export default ContactsSection;
