import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './Button.module.scss';
import IButton from './IButton';

const Button: FC<IButton> = ({
	children,
	className,
	negative,
	width,
	small,
	onClick,
	href,
	...rest
}) => {
	return (
		// eslint-disable-next-line jsx-a11y/anchor-is-valid
		<a
			className={classNames(
				styles.button,
				className,
				negative ? styles.negative : '',
				small ? styles.small : '',
			)}
			style={{ width }}
			{...rest}
			target="_blank"
			onClick={onClick}
			href={href}
			rel="noopener noreferrer"
		>
			<span className={styles.text}>{children}</span>
		</a>
	);
};

export default Button;
