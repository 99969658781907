import React, { FC } from 'react';
import styles from './AboutSection.module.scss';
import classNames from 'classnames';

const AboutSection: FC = () => {
	return (
		<div className={styles.about}>
			<div className={styles.container}>
				<h2>
					Your ultimate
					<br /> compliance powerhouse!
				</h2>

				<div className={styles.content}>
					<div className={classNames(styles.block, styles['top-left'])}>
						<p>
							<strong>Gravity Security</strong> is a pioneering Governance, Risk, and Compliance (GRC)
							startup founded in 2023. Headquartered in vibrant Orlando, FL, our
							company was established by two seasoned leaders in the Security and
							Compliance industry.
						</p>
					</div>

					<div className={classNames(styles.block, styles['top-right'])}>
						<p>
							With a client-centric approach, we prioritize understanding our clients'
							challenges and delivering bespoke solutions to address them effectively.
							Our commitment to excellence drives us to stay ahead of emerging threats
							and regulatory changes, keeping our clients one step ahead in their
							compliance journey.
						</p>
					</div>

					<div className={classNames(styles.block, styles['bottom-left'])}>
						<p>
							At Gravity Security, we specialize in providing <strong>comprehensive GRC
							solutions</strong> tailored to meet the unique needs of the modern business
							world. Our team brings decades of combined experience to the table,
							ensuring unparalleled expertise in navigating complex regulatory
							landscapes. We pride ourselves on offering innovative technologies and
							strategies to <strong>mitigate risks, enhance security posture, and foster
							regulatory compliance</strong>.
						</p>
					</div>

					<div className={classNames(styles.block, styles['bottom-right'])}>
						<p>
							Partner with us to unlock new levels of efficiency, security, and trust
							in your business operations. <strong>Experience peace of mind</strong> knowing that your
							GRC needs are in the hands of industry leaders dedicated to your
							success. Join us on the journey to a safer, more compliant future with
							Gravity Security.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AboutSection;
