import React, { FC } from 'react';
import styles from './Navigation.module.scss';
import INavigation from './INavigation';
import { PageSections } from '../../../utils/constants';
import classNames from 'classnames';

const Navigation: FC<INavigation> = ({ className, activeMenuItem, scrollToSection }) => {
	return (
		<nav className={classNames(styles.navigation, className)}>
			{PageSections.map((section) => (
				<span
					key={section.key}
					className={classNames(
						styles.item,
						activeMenuItem === section.key ? styles.active : '',
					)}
					onClick={() => scrollToSection(section.key)}
				>
					{section.displayName}
				</span>
			))}
		</nav>
	);
};

export default Navigation;
